


































































import vSelect from 'vue-select';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { QueryLoadOption, SortingInfo } from "@/models/QueryLoadOptions";
import { OptionTransactionType } from "@/models/customer/activity/ActivityResult";
import { Common } from "@/helper/common";
import { LoadResult } from "@/models/LoadResult";
import * as _ from 'lodash';
import { OrderService } from "@/views/order/OrderService";
import { OrderSearchResult, OptionOrderStatus } from "@/models/order/OrderSearchResult";
import { Status } from "@/constants/Order";
import moment from "moment";

@Component({
    components: {
        vSelect
    }
})
export default class CustomerOrderList extends Vue {
    currentPage: number = 1;
    pageSize = 10; // default 10
    searchTerm = '';
    debounce: any = null;
    status: OptionTransactionType[] = [];
    // Search loadOption
    loadOptions: QueryLoadOption = new QueryLoadOption();
    common = new Common();
    orders: OrderSearchResult[] = [];
    totalPages = 0;
    customerId: string;

    listType = [
        new OptionOrderStatus(Status.Draft),
        new OptionOrderStatus(Status.Ordered),
        new OptionOrderStatus(Status.GoodsIssued),
        new OptionOrderStatus(Status.Delivering),
        new OptionOrderStatus(Status.Delivered),
        new OptionOrderStatus(Status.WaitingForReturnAndExchange),
        new OptionOrderStatus(Status.GoodsNotDelivered),
        new OptionOrderStatus(Status.Canceled),
        new OptionOrderStatus(Status.TotalOrder),
    ];

    private orderService = new OrderService();

    constructor() {
        super();
        this.loadOptions.select = ['id', 'code', 'creatorFullName', 'createTime', 'totalPrice', 'status'];
        this.loadOptions.sort = [new SortingInfo('createTime', true)];
    }

    created() {
        this.customerId = this.$route.params.id;
    }

    mounted() {
        this.search();
    }

    @Watch('currentPage')
    getByCurrentPage(value: number) {
        this.search();
    }

    @Watch('searchTerm')
    searchByTerm(value: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.rendererQuery();
        this.debounce = _.debounce(() => this.search(), 500);
        this.debounce();
    }

    selectValue(values: OptionTransactionType[]) {
        this.status = values;
        this.rendererQuery();
        this.currentPage = 1;
        this.search();
    }

    showAddForm() {
    }

    beginSearch(page: number) {
        if (this.searchTerm == undefined || this.searchTerm.length < 2) {
            return;
        }

        this.search();
    }

    get chipColor() {
        return (value: any) => {
            return new OptionOrderStatus(value).color;
        }
    }

    statusName(value: any) {
        return new OptionOrderStatus(value).name;
    }

    private async search() {
        this.loadOptions.skip = (this.currentPage - 1) * this.pageSize;
        this.loadOptions.take = this.pageSize;
        this.loadOptions.requireTotalCount = true;
        this.loadOptions.remoteSelect = false;
        // const customerId = this.$route.params.id;

        this.openLoading(true);

        this.orderService.getByCustomer(this.loadOptions, this.customerId).finally(() => {
            this.openLoading(false);
        }).then((result: LoadResult) => {
            this.orders = result.data;
            this.setTotalPage(result.totalCount);
        })
    }

    private rendererQuery() {
        const paramsKeyword: any[] = [];
        if (this.searchTerm !== null && this.searchTerm !== '') {
            paramsKeyword.push(this.common.renderQuery('normalizedName', "contains", this.searchTerm.toUpperCase()));
        }

        const paramsType: any[] = [];
        if (this.status && this.status.length > 0) {
            _.each(this.status, (item: OptionTransactionType, index: number) => {
                paramsType.push(this.common.renderQuery('status', "=", item.id.toString()))
                if (index < this.status.length - 1) {
                    paramsType.push('or');
                }
            })
        }

        const params: any = [];
        if (paramsKeyword && paramsKeyword.length > 0) {
            params.push(paramsKeyword);
        }

        if (paramsType && paramsType.length > 0) {
            if (paramsKeyword && paramsKeyword.length > 0) {
                params.push('and');
            }

            params.push(paramsType);
        }

        this.loadOptions.filterQuery = params && params.length > 0 ? JSON.stringify(params) : '';
    }

    formatDate(date: any) {
        return moment(date).format('DD-MM-YYYY HH:mm')
    }

    private setTotalPage(totalRows: number) {
        if (totalRows == 0) {
            this.totalPages = 0;
        } else if (totalRows <= this.pageSize) {
            this.totalPages = 1;
        } else {
            this.totalPages = Math.ceil((totalRows / this.pageSize));
        }
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }
}
