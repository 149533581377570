import {Status} from "@/constants/Order";

export interface OrderSearchResult {
    id: string;
    name: string;
    createTime: string;
}

export class OptionOrderStatus {
    id: number;
    name: string;
    color: string;
    colorDescription: string;

    constructor(id: number) {
        this.id = id;
        switch (id) {
            case Status.Draft:
                this.name = 'Nháp';
                this.color = 'dark';
                this.colorDescription = '#1E1E1E'
                return;
            case Status.Ordered:
                this.name = 'Đặt hàng';
                this.color = 'primary';
                this.colorDescription = '#7367F0'
                return;
            case Status.GoodsIssued:
                this.name = 'Đã xuất kho';
                this.color = 'success';
                this.colorDescription = '#28C76F'
                return;
            case Status.Delivering:
                this.name = 'Đang giao';
                this.color = 'warning';
                this.colorDescription = '#FF9F43';
                return;
            case Status.Delivered:
                this.name = 'Đã giao';
                this.color = 'success';
                this.colorDescription = '#28C76F';
                return;
            case Status.WaitingForReturnAndExchange:
                this.name = 'Chờ đổi trả hàng';
                this.color = 'warning';
                this.colorDescription = '#FF9F43';
                return;
            case Status.GoodsNotDelivered:
                this.name = 'Không giao được';
                this.color = 'danger';
                this.colorDescription = '#EA5455';
                return;
            case Status.Canceled:
                this.name = 'Đã hủy';
                this.color = 'danger';
                this.colorDescription = '#EA5455';
                return;
            case Status.TotalOrder:
                this.name = 'Đơn tổng';
                this.color = '';
                this.colorDescription = '#7367F0';
                return;
            default:
                this.name = '';
                this.color = 'dark';
                this.colorDescription = '#1E1E1E';
                return;
        }
    }
}
